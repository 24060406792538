import * as R from "ramda"
import {useState} from "react"

import useCurrentUser from "src/features/auth/hooks/useCurrentUser"
import {useUserPermission} from "src/features/auth/hooks/useUserPermissions"
import {
  GetTicketQuery,
  PeachRefundData,
  Permission,
  StripePaymentIntent,
  Ticket,
  TicketPaymentType,
  useGetTicketQuery,
  useGetTicketReceiptUrlLazyQuery,
} from "src/types/apollo"
import withAuth, {authedOrFallback} from "../../withAuth"

import {
  Email as EmailIcon,
  FileCopy as FileCopyIcon,
  Home as AddressIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material"
import Page from "../../../components/Page"

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  Typography,
} from "@mui/material"

import {blue, green, grey, red} from "@mui/material/colors"

import moment from "moment"
import {useNavigate, useParams} from "react-router-dom"
import ResponsiveTable, {Column} from "src/components/ResponsiveTable"
import {Scorecard} from "src/components/Scorecard"
import {AuditLogTable} from "src/features/auditLog/controllers/AuditLogTable"
import AddAddonModal from "src/features/setup/components/AddAddonModal"
import {VIEW_TICKETS_ONLY} from "src/features/users/components/UserPermissionsWidget"
import withBusiness from "src/features/withBusiness"
import {refundReasonTextMap} from "src/textResources"
import {formatPrice} from "src/utils/formatPrice"
import Badge from "../../../components/Badge"
import {ActionFailedSnackbar} from "../components/ActionFailedSnackbar"
import {ActionSuccessSnackbar} from "../components/ActionSuccessSnackbar"
import CheckinHistory from "../components/CheckinHistory"
import CheckInVistorModal from "../components/CheckInVisitorModal"
import {SpinnerSvg} from "../components/GatehousePage/forms/Spinner"
import ManageSharingModal from "../components/ManageSharingModal"
import RefundPaymentModal from "../components/RefundPaymentModal"
import RenewTicketModal from "../components/RenewTicketModal"
import TicketStatusBadge from "../components/TicketStatusBadge"
import UpdateTicketExpiryModal from "../components/UpdateTicketExpiryModal"
import UpdateTicketTimeslotModal from "../components/UpdateTicketTimeslotModal"
import UpdateTicketVisitorsModal from "../components/UpdateTicketVisitorsModal"
import {DeleteTicketButton} from "../controllers/DeleteTicketButton"
import {getTicketTypeTicketVisitorsWithUserIdDisplay} from "../helpers/getTicketTypeTicketVisitorsDisplay"
import {STATUS, visitorsColumn} from "./TicketSearchPage"
import Case from "case"
import CancelSubscriptionModal from "../components/CancelSubscriptionModal"
import {enqueueSnackbar} from "notistack"

const dateFormat = "lll"

const paymentStatusColorMap = {
  PAID: green[300],
  UNPAID: grey[700],
  REFUNDED: blue[500],
  FAILED: red[400],
}

interface State {
  addAddonModalOpen: boolean
  refundPaymentModalOpen: boolean
  cancelSubscriptionModalOpen: boolean
  updateTicketExpiryModalOpen: boolean
  updateTicketVisitorsModalOpen: boolean
  renewTicketModalOpen: boolean
  checkInVisitorModalOpen: boolean
  ticketTokenDetailsModalOpen: boolean
  manageSharingModalOpen: boolean
  snackbarSuccessMessage: string | null
  snackbarFailedMessage: string | null
}

const initialData: State = {
  addAddonModalOpen: false,
  refundPaymentModalOpen: false,
  cancelSubscriptionModalOpen: false,
  updateTicketExpiryModalOpen: false,
  updateTicketVisitorsModalOpen: false,
  renewTicketModalOpen: false,
  checkInVisitorModalOpen: false,
  ticketTokenDetailsModalOpen: false,
  manageSharingModalOpen: false,
  snackbarSuccessMessage: null,
  snackbarFailedMessage: null,
}

type VisitorTypeHashType = {
  visitorType: {
    id: string
  }
  firstName?: string | null
  lastName?: string | null
}

type TicketHashType = {
  ticketType: {
    id: string
    name: string
  }
  visitors: Array<VisitorTypeHashType>
}

export function getTicketTypesHash(ticketTypes: Array<TicketHashType>) {
  return ticketTypes.reduce<{
    [key: string]: {
      id: string
      name: string
      ticketTypes: Array<Array<VisitorTypeHashType>>
    }
  }>((acc, val) => {
    const ticketTypes = acc[val.ticketType.id]
      ? [...acc[val.ticketType.id].ticketTypes, val.visitors]
      : [val.visitors]

    return {
      ...acc,
      [val.ticketType.id]: {
        id: val.ticketType.id,
        name: val.ticketType.name,
        ticketTypes,
      },
    }
  }, {})
}

function TicketPage({businessName}: {businessName: string | undefined}) {
  const navigate = useNavigate()
  const {id} = useParams<{id: string}>() as {id: string}
  const hasRefundTicketPermission = useUserPermission(
    Permission.CmsRefundPayments,
  )
  const hasEditTicketsPermission = useUserPermission(Permission.CmsEditTickets)
  const hasDeleteTicketPermission = useUserPermission(
    Permission.CmsDeleteTickets,
  )
  const hasViewCheckinTokensPermission = useUserPermission(
    Permission.CmsCanViewCheckinTokens,
  )
  const currentUser = useCurrentUser()
  const [state, setState] = useState(initialData)

  const {data, loading} = useGetTicketQuery({
    variables: {id: [id], hasViewCheckinTokensPermission},
    errorPolicy: "all",
  })

  const [receiptImageDisplayFailed, setReceiptImageDisplayFailed] =
    useState(false)
  const [
    fetchReceiptUrl,
    {
      data: receiptData,
      error: receiptError,
      loading: receiptLoading,
      called: receiptFetchCalled,
    },
  ] = useGetTicketReceiptUrlLazyQuery({
    variables: {ticketId: [id]},
  })

  if (loading) {
    return <Page loading />
  }

  const ticket = data?.tickets[0]
  const meData = data?.me

  if (ticket == null) {
    return <Page error={`Could not find ticket in ${businessName}`} />
  }

  const ticketTypesHash = getTicketTypesHash(ticket.ticketTypes)

  const buttons = [
    {
      type: "link" as const,
      text: "Edit",
      to: `/tickets/${ticket.id}/edit`,
      icon: "edit",
      dataCy: "document-edit",
    },
  ]

  const stripeRefundColumns: Array<
    Column<StripePaymentIntent["refunds"][number]>
  > = [
    {
      title: "Time",
      getValue: ({createdAt}) => {
        return (
          <div style={{minWidth: 150}}>
            {createdAt && moment(createdAt * 1000).format(dateFormat)}
          </div>
        )
      },
    },
    {
      title: "Amount",
      getValue: ({amount, currencyCode}) => {
        if (!amount) {
          return null
        }
        return formatPrice(currencyCode, amount)
      },
    },
    {
      title: "Reason",
      getValue: ({reason}) => {
        return (
          <div style={{minWidth: 150}}>
            {reason
              ? refundReasonTextMap[reason as keyof typeof refundReasonTextMap]
              : "Other"}
          </div>
        )
      },
    },
    {
      title: "Notes",
      getValue: ({notes}) => {
        return notes || "None"
      },
    },
  ]

  const peachRefundColumns: Array<Column<PeachRefundData>> = [
    {
      title: "Time",
      getValue: ({timestamp}) => {
        return <div style={{minWidth: 150}}>{timestamp}</div>
      },
    },
    {
      title: "Amount",
      getValue: ({amount, currencyCode}) => {
        if (!amount) {
          return null
        }
        return formatPrice(currencyCode, amount)
      },
    },
  ]

  const showRenewTicketAction =
    (ticket.paymentType !== TicketPaymentType.Subscription ||
      !ticket.paymentData ||
      !("renewalIsCancelled" in ticket.paymentData) ||
      ticket.paymentData.renewalIsCancelled) &&
    ticket.schedule == null
  const showUpdateVisitorsAction = hasEditTicketsPermission
  const showUpdateExpiryAction = hasEditTicketsPermission
  const showTicketDetailActions =
    showRenewTicketAction || showUpdateVisitorsAction || showUpdateExpiryAction

  const showRefundPaymentAction =
    hasRefundTicketPermission &&
    (ticket.paymentData?.__typename === "TicketPaymentDataPaymentIntent" ||
      ticket.paymentData?.__typename === "TicketPaymentDataPeachPayment")

  const showCheckinAction =
    currentUser && currentUser.business?.id && ticket?.status === "ACTIVE"
  const showCheckinHistoryActions = !!showCheckinAction

  const toggleModal = (modalName: string) => {
    const propName = `${modalName}ModalOpen`
    setState({
      ...state,
      [propName]: !state[propName as keyof typeof state],
    })
  }

  const marketingPreferencesDescription = (
    preferences: NonNullable<
      GetTicketQuery["tickets"][number]
    >["receiveMarketingBy"],
  ) => {
    if (!meData?.associatedBusiness?.usesMarketingPreferences) {
      return "Not managed by Candide Admissions"
    }

    const preferencesArray = []
    if (preferences.phone) {
      preferencesArray.push("Phone")
    }

    if (preferences.email) {
      preferencesArray.push("Email")
    }

    if (preferencesArray.length === 0) {
      return "None"
    }

    return preferencesArray.join(", ").toString()
  }

  const handleSnackbarClose = () => {
    setState({
      ...state,
      snackbarSuccessMessage: null,
      snackbarFailedMessage: null,
    })
  }

  const getSubscriptionStatusText = (
    t: NonNullable<GetTicketQuery["tickets"][number]>,
  ): string => {
    return t.paymentData?.__typename === "TicketPaymentDataSubscription" &&
      t.paymentData?.renewalIsCancelled &&
      t.paymentStatus === "FAILED"
      ? "Failed (Subscription not created)"
      : t.paymentData?.__typename === "TicketPaymentDataSubscription" &&
          t.paymentData?.renewalIsCancelled
        ? "Cancelled (Membership won't renew on expiry)"
        : "Active (Membership will renew on expiry)"
  }

  const AuditLogCard = authedOrFallback([Permission.CanAccessAuditLog])(() => (
    <Card>
      <CardHeader title="Audit Log" />
      <CardContent>
        <AuditLogTable
          options={{
            entityType: "ticket",
            entityId: ticket.id,
            afterDate: null,
            beforeDate: null,
            userId: null,
            type: null,
          }}
        />
      </CardContent>
    </Card>
  ))

  return (
    <Page title={ticket.product.name} buttons={buttons}>
      <Container maxWidth="md">
        <Card elevation={2}>
          <CardHeader title="Visitor" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <List>
                  <ListItem
                    secondaryAction={
                      ticket.email != null ? (
                        <IconButton
                          edge="end"
                          aria-label="copy"
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(ticket.email!)
                              setState({
                                ...state,
                                snackbarSuccessMessage: "Email copied",
                              })
                            } catch (_e) {
                              setState({
                                ...state,
                                snackbarFailedMessage: "Couldn't copy email",
                              })
                            }
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      ) : null
                    }
                  >
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={ticket.email != null ? ticket.email : "No email"}
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      ticket.phone != null ? (
                        <IconButton
                          edge="end"
                          aria-label="copy"
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(ticket.phone!)
                              setState({
                                ...state,
                                snackbarSuccessMessage: "Phone number copied",
                              })
                            } catch (_e) {
                              setState({
                                ...state,
                                snackbarFailedMessage:
                                  "Couldn't copy phone number",
                              })
                            }
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      ) : null
                    }
                  >
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        ticket.phone != null ? ticket.phone : "No phone number"
                      }
                    />
                  </ListItem>
                  {ticket.address && ticket.address.length > 0 && (
                    <ListItem
                      data-cy="ticket-address"
                      secondaryAction={
                        ticket.address && ticket.address.length > 0 ? (
                          <IconButton
                            edge="end"
                            aria-label="copy"
                            onClick={async () => {
                              try {
                                await navigator.clipboard.writeText(
                                  ticket.address.map((a) => a.value).toString(),
                                )
                                setState({
                                  ...state,
                                  snackbarSuccessMessage: "Address copied",
                                })
                              } catch (_e) {
                                setState({
                                  ...state,
                                  snackbarFailedMessage:
                                    "Couldn't copy address",
                                })
                              }
                            }}
                          >
                            <FileCopyIcon />
                          </IconButton>
                        ) : null
                      }
                    >
                      <ListItemIcon>
                        <AddressIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={R.pluck("value", ticket.address)
                          .filter((a) => a.length > 0)
                          .join(", ")}
                      />
                    </ListItem>
                  )}
                </List>
              </Grid>
              <Grid item xs={12} md={4}>
                <Scorecard
                  title={"No. of checkins"}
                  total={ticket.checkins.totalCount}
                  FooterComponent={
                    ticket.checkins.results[0]?.checkinTime &&
                    ticket.checkins.totalCount >= 5 && (
                      <Box display="flex" justifyContent={"space-between"}>
                        <Typography variant="subtitle1">
                          Last visited
                        </Typography>
                        <Typography variant="subtitle1">
                          {moment(
                            ticket.checkins.results[0].checkinTime,
                          ).format("Do MMM YYYY")}
                        </Typography>
                      </Box>
                    )
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            {showCheckinAction && (
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  toggleModal("checkInVisitor")
                }}
              >
                Check In Visitor
              </Button>
            )}
          </CardActions>
        </Card>
        <div style={{height: "1rem"}} />
        <Card elevation={2}>
          <CardHeader title={`${ticket.product.name} Details`} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <>
                  {Object.values(ticketTypesHash).map(
                    ({id, name, ticketTypes}) => (
                      <div
                        style={{
                          marginLeft: 18,
                          marginBottom: 8,
                          marginTop: 16,
                        }}
                        key={id}
                      >
                        <Typography variant="h5" style={{marginBottom: 4}}>
                          {`${ticketTypes.length}x ${name}`}
                        </Typography>
                        {ticketTypes.map((tt) => {
                          return getTicketTypeTicketVisitorsWithUserIdDisplay(
                            tt,
                          ).map((rows) => (
                            <div
                              style={{display: "flex", flexDirection: "column"}}
                            >
                              {rows}
                            </div>
                          ))
                        })}
                      </div>
                    ),
                  )}
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Status
                      </TableCell>
                      <TableCell align="right">
                        {<TicketStatusBadge status={ticket.status} />}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Created at
                      </TableCell>
                      <TableCell align="right">
                        {moment(ticket.createdAt).format(dateFormat)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Starts at
                      </TableCell>
                      <TableCell align="right">
                        {moment(ticket.startsAt).format(dateFormat)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Expires at
                      </TableCell>
                      <TableCell align="right">
                        {moment(ticket.expiresAt).format(dateFormat)}
                      </TableCell>
                    </TableRow>
                    {ticket.deletedAt && (
                      <TableRow
                        sx={(theme) => ({
                          borderColor: theme.palette.secondary.main,
                          borderWidth: "2px",
                          borderStyle: "solid",
                        })}
                      >
                        <TableCell component="th" scope="row">
                          Deletes at
                        </TableCell>
                        <TableCell align="right">
                          {moment(ticket.deletedAt).format(dateFormat)}
                        </TableCell>
                      </TableRow>
                    )}
                    {ticket.schedule?.id != null && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Schedule Id
                        </TableCell>
                        <TableCell align="right">
                          {ticket.schedule?.id}
                        </TableCell>
                      </TableRow>
                    )}
                    {ticket.ticketToken && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Creation method
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            href={`/ticket-codes/${ticket.ticketToken.id}`}
                          >
                            Ticket Token
                          </Link>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
          {(ticket.previousTicket?.id || ticket.nextTicket?.id) && (
            <CardActions dir="rtl">
              <>
                {ticket.nextTicket?.id && (
                  <Button
                    href={`/tickets/${ticket.nextTicket.id}`}
                    color="info"
                  >
                    Next ticket
                  </Button>
                )}
                {ticket.previousTicket?.id && ticket.nextTicket?.id && (
                  <span>&nbsp;|&nbsp;</span>
                )}
                {ticket.previousTicket?.id && (
                  <Button
                    href={`/tickets/${ticket.previousTicket.id}`}
                    color="info"
                  >
                    Previous ticket
                  </Button>
                )}
              </>
            </CardActions>
          )}
          {showTicketDetailActions && (
            <CardActions>
              {showUpdateVisitorsAction && (
                <>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      toggleModal("updateTicketVisitors")
                    }}
                  >
                    Update Visitors
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      toggleModal("manageSharing")
                    }}
                  >
                    Manage Sharing
                  </Button>
                </>
              )}
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  toggleModal("updateTicketExpiry")
                }}
              >
                {ticket.product.schedules != null
                  ? "Update Timeslot"
                  : "Update Expiry"}
              </Button>
              {showRenewTicketAction && (
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => {
                    toggleModal("renewTicket")
                  }}
                >
                  Renew Ticket
                </Button>
              )}
            </CardActions>
          )}
        </Card>
        <div style={{height: "1rem"}} />
        {ticket.paymentData && (
          <Card>
            <CardHeader title="Payment" />
            <CardContent>
              <Table style={{width: "100%"}}>
                <TableBody>
                  {ticket.paymentStatus && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Payment status
                      </TableCell>
                      <TableCell align="right">
                        <Badge
                          text={ticket.paymentStatus}
                          backgroundColor={
                            paymentStatusColorMap[ticket.paymentStatus]
                          }
                        />
                        {"renewalIsCancelled" in ticket.paymentData &&
                          ticket.paymentData.renewalIsCancelled &&
                          ticket.paymentType ===
                            TicketPaymentType.Subscription && (
                            <>
                              &nbsp;
                              <Badge
                                text={"Cancelled"}
                                backgroundColor={
                                  paymentStatusColorMap["FAILED"]
                                }
                              />
                            </>
                          )}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Payment type
                    </TableCell>
                    {ticket.paymentType !== TicketPaymentType.NotRequired ? (
                      <TableCell align="right">
                        {Case.title(ticket.paymentType)}
                      </TableCell>
                    ) : null}
                  </TableRow>
                  {ticket.paymentData?.__typename ===
                    "TicketPaymentDataSubscription" &&
                    ticket.paymentData.subscription.subscriptionId && (
                      <>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Subscription Id
                          </TableCell>
                          <TableCell align="right">
                            {ticket.paymentData.subscription.subscriptionId}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Subscription status
                          </TableCell>
                          <TableCell align="right">
                            {getSubscriptionStatusText(ticket)}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {ticket.paymentData?.__typename ===
                    "TicketPaymentDataPaymentIntent" &&
                    ticket.paymentData.paymentIntent?.id && (
                      <>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Payment intent id
                          </TableCell>
                          <TableCell align="right">
                            {ticket.paymentData.paymentIntent?.id}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Amount paid
                          </TableCell>
                          <TableCell align="right">
                            {formatPrice(
                              ticket.paymentData.paymentIntent.currencyCode,
                              ticket.paymentData.paymentIntent?.amount,
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Card Number
                          </TableCell>
                          <TableCell align="right">
                            {ticket.paymentData.paymentIntent.last4
                              ? `XXXX XXXX XXXX ${ticket.paymentData.paymentIntent.last4}`
                              : "Not Present"}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {ticket.paymentData?.__typename ===
                    "TicketPaymentDataPeachPayment" &&
                    ticket.paymentData.data?.id && (
                      <>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Transaction ID
                          </TableCell>
                          <TableCell align="right">
                            {ticket.paymentData.data?.transactionId}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Unique ID
                          </TableCell>
                          <TableCell align="right">
                            {ticket.paymentData.data?.id}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Amount paid
                          </TableCell>
                          <TableCell align="right">
                            {formatPrice(
                              ticket.paymentData.data.currencyCode,
                              ticket.paymentData.data?.amount,
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Card Holder
                          </TableCell>
                          <TableCell align="right">
                            {ticket.paymentData.data?.cardHolder}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Card Number
                          </TableCell>
                          <TableCell align="right">
                            {ticket.paymentData.data.last4
                              ? `XXXX XXXX XXXX ${ticket.paymentData.data.last4}`
                              : "Not Present"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Result
                          </TableCell>
                          <TableCell align="right">
                            {ticket.paymentData.data?.resultDescription}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {ticket.receipt && (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Receipt
                        </TableCell>
                        {!receiptFetchCalled && (
                          <TableCell align="right">
                            <Button
                              variant="outlined"
                              onClick={() => fetchReceiptUrl()}
                            >
                              Show
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>

                      <TableRow>
                        <TableCell style={{paddingLeft: 48}}>
                          {receiptImageDisplayFailed ? (
                            <Typography>
                              Failed to display receipt. This could mean that no
                              receipt has been uploaded for this ticket, or this
                              could be a temporary issue with loading the image
                            </Typography>
                          ) : receiptData ? (
                            <img
                              src={receiptData.tickets[0]?.receipt?.url}
                              alt="Ticket receipt"
                              onError={() => setReceiptImageDisplayFailed(true)}
                              style={{maxWidth: "100%"}}
                            />
                          ) : receiptLoading ? (
                            <SpinnerSvg animated />
                          ) : receiptError ? (
                            <Typography>
                              Failed to load receipt, please try again
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
              {ticket.paymentData.__typename ===
                "TicketPaymentDataPaymentIntent" &&
                ticket.paymentData.paymentIntent.refunds.length > 0 && (
                  <div style={{marginTop: 32}}>
                    <Typography variant="h6">Refunds</Typography>
                    <ResponsiveTable
                      columns={stripeRefundColumns}
                      data={ticket.paymentData.paymentIntent.refunds}
                      hasMore={false}
                      loading={false}
                      error={false}
                      loadingMore={false}
                      getKey={(row) => row.id}
                      onRequestLoadMore={() => {}}
                    />
                  </div>
                )}

              {ticket.paymentData.__typename ===
                "TicketPaymentDataPeachPayment" &&
                ticket.paymentData.data.refunds.length > 0 && (
                  <div style={{marginTop: 32}}>
                    <Typography variant="h6">Refunds</Typography>
                    <ResponsiveTable
                      columns={peachRefundColumns}
                      data={ticket.paymentData.data.refunds}
                      hasMore={false}
                      loading={false}
                      error={false}
                      loadingMore={false}
                      getKey={(row) => row.id}
                      onRequestLoadMore={() => {}}
                    />
                  </div>
                )}
            </CardContent>
            <CardActions>
              {showRefundPaymentAction && (
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => {
                    toggleModal("refundPayment")
                  }}
                >
                  Refund Payment
                </Button>
              )}

              {hasEditTicketsPermission &&
                ticket.paymentType === TicketPaymentType.Subscription &&
                "renewalIsCancelled" in ticket.paymentData &&
                !ticket.paymentData.renewalIsCancelled && (
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      toggleModal("cancelSubscription")
                    }}
                  >
                    Cancel Subscription
                  </Button>
                )}
            </CardActions>
          </Card>
        )}
        <div style={{height: "1rem"}} />
        <Card>
          <CardHeader title="Other" />
          <CardContent>
            <Table style={{width: "auto"}}>
              <TableBody>
                {ticket.activeCheckinToken && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Active checkin token
                    </TableCell>
                    <TableCell
                      align="right"
                      data-cy="ticket-activeCheckinToken"
                    >
                      {ticket.activeCheckinToken}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell component="th" scope="row">
                    Admin notes
                  </TableCell>
                  <TableCell align="right">{ticket.adminNotes}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has product review requirements?
                  </TableCell>
                  <TableCell align="right">
                    {(ticket.product.reviewRequirements ?? []).length > 0
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
                {(ticket.product.reviewRequirements ?? []).length > 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Review requirements verified?
                    </TableCell>
                    <TableCell align="right">
                      {ticket.reviewRequirementsVerified ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell component="th" scope="row">
                    Marketing preferences
                  </TableCell>
                  <TableCell align="right">
                    {marketingPreferencesDescription(ticket.receiveMarketingBy)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Renewal validation status
                  </TableCell>
                  <TableCell align="right">
                    {ticket.renewalValidationStatus}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
          <CardActions>
            {hasDeleteTicketPermission && (
              <DeleteTicketButton
                ticketId={ticket.id}
                expiresAt={ticket.expiresAt}
                deletedAt={ticket.deletedAt}
                onMarkedForDeletionFailed={(snackbarFailedMessage) =>
                  setState({
                    ...state,
                    snackbarFailedMessage,
                  })
                }
                onMarkedForDeletionSucceeded={(snackbarSuccessMessage) =>
                  setState({
                    ...state,
                    snackbarSuccessMessage,
                  })
                }
              />
            )}
          </CardActions>
        </Card>
        <div style={{height: "1rem"}} />
        <Card>
          <CardHeader title="Previous Checkins" />
          <CardContent>
            <CheckinHistory ticket={ticket} />
          </CardContent>
          {showCheckinHistoryActions && (
            <CardActions>
              {showCheckinAction && (
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => {
                    toggleModal("checkInVisitor")
                  }}
                >
                  Check In Visitor
                </Button>
              )}
            </CardActions>
          )}
        </Card>
        <div style={{height: "1rem"}} />
        <Card>
          <CardHeader title="Addon Parent Ticket" />
          <CardContent>
            {/* A table is used here to make the style consistent, the data doesn't really need it */}
            <ResponsiveTable
              emptyMessage="This ticket wasn't purchased as an addon"
              columns={[
                {
                  title: "Product Name",
                  getValue: ({product}) => product.name,
                },
                STATUS,
                visitorsColumn(),
              ]}
              data={
                ticket.addonParentTicket == null
                  ? []
                  : [ticket.addonParentTicket]
              }
              hasMore={false}
              loading={false}
              error={false}
              loadingMore={false}
              getLink={(row) => `/tickets/${row.id}`}
              getKey={(row) => row.id}
              onRequestLoadMore={() => {}}
            />
          </CardContent>

          <Divider
            style={{marginBottom: 28, marginLeft: 16, marginRight: 16}}
          />

          <CardHeader title="Addon Tickets" />
          <CardContent>
            <ResponsiveTable
              emptyMessage="No addons purchased for this ticket"
              columns={[
                {
                  title: "Product Name",
                  getValue: ({product}) => product.name,
                },
                STATUS,
                visitorsColumn(),
              ]}
              data={ticket.addonTickets}
              hasMore={false}
              loading={false}
              error={false}
              loadingMore={false}
              getLink={(row) => `/tickets/${row.id}`}
              getKey={(row) => row.id}
              onRequestLoadMore={() => {}}
            />
          </CardContent>
          <CardActions>
            <Button
              color="secondary"
              size="small"
              onClick={() => {
                toggleModal("addAddon")
              }}
            >
              Add a new addon
            </Button>
          </CardActions>
        </Card>

        <div style={{height: "1rem"}} />
        <AuditLogCard />
      </Container>
      <AddAddonModal
        ticket={ticket as Ticket}
        modalOpen={state.addAddonModalOpen}
        toggleModal={() => toggleModal("addAddon")}
      />
      <RefundPaymentModal
        ticket={ticket}
        modalOpen={state.refundPaymentModalOpen}
        toggleModal={() => toggleModal("refundPayment")}
        onSuccess={() => {
          setState({
            ...state,
            refundPaymentModalOpen: false,
            snackbarSuccessMessage: "Refund Successful",
          })
        }}
      />
      <CancelSubscriptionModal
        ticket={ticket}
        modalOpen={state.cancelSubscriptionModalOpen}
        toggleModal={() => toggleModal("cancelSubscription")}
        onSuccess={() => {
          setState({
            ...state,
            cancelSubscriptionModalOpen: false,
          })
          enqueueSnackbar({
            message: "Subscription Cancelled",
            variant: "success",
          })
        }}
        onError={() => {
          setState({
            ...state,
            cancelSubscriptionModalOpen: false,
          })
          enqueueSnackbar("Failed to cancel subscription", {variant: "error"})
        }}
      />
      {state.updateTicketExpiryModalOpen ? (
        ticket.product?.schedules == null ? (
          <UpdateTicketExpiryModal
            ticket={ticket as Ticket}
            modalOpen={state.updateTicketExpiryModalOpen}
            toggleModal={() => toggleModal("updateTicketExpiry")}
            onSuccess={() => {
              setState({
                ...state,
                updateTicketExpiryModalOpen: false,
                snackbarSuccessMessage: "Expiry Changed",
              })
            }}
          />
        ) : (
          <UpdateTicketTimeslotModal
            modalOpen
            onSuccess={() => {
              setState({
                ...state,
                updateTicketExpiryModalOpen: false,
                snackbarSuccessMessage: "Timeslot Changed",
              })
            }}
            schedules={ticket.product.schedules}
            ticket={ticket as Ticket}
            toggleModal={() => toggleModal("updateTicketExpiry")}
          />
        )
      ) : null}
      {state.updateTicketVisitorsModalOpen && (
        <UpdateTicketVisitorsModal
          ticket={ticket as Ticket}
          modalOpen
          toggleModal={() => toggleModal("updateTicketVisitors")}
          onSuccess={() => {
            setState({
              ...state,
              updateTicketVisitorsModalOpen: false,
              snackbarSuccessMessage: "Visitors Changed",
            })
          }}
        />
      )}
      <ManageSharingModal
        ticket={ticket}
        modalOpen={state.manageSharingModalOpen}
        toggleModal={() => toggleModal("manageSharing")}
      />
      <RenewTicketModal
        ticket={ticket}
        modalOpen={state.renewTicketModalOpen}
        toggleModal={() => toggleModal("renewTicket")}
        onSuccess={(newTicketId) => {
          setState({
            ...state,
            renewTicketModalOpen: false,
            snackbarSuccessMessage: "Renew Successful",
          })

          navigate(`/tickets/${newTicketId}`)
        }}
      />
      <CheckInVistorModal
        ticketId={ticket.id}
        modalOpen={state.checkInVisitorModalOpen}
        toggleModal={() => toggleModal("checkInVisitor")}
        onSuccess={() => {
          setState({
            ...state,
            checkInVisitorModalOpen: false,
            snackbarSuccessMessage: "Checkin Successful",
          })
        }}
      />
      <ActionSuccessSnackbar
        message={state.snackbarSuccessMessage}
        onSnackbarClose={handleSnackbarClose}
      />
      <ActionFailedSnackbar
        message={state.snackbarFailedMessage}
        onSnackbarClose={handleSnackbarClose}
      />
    </Page>
  )
}

const TableCell = styled(MuiTableCell)({
  root: {
    borderBottom: "none",
  },
})

export default withAuth(VIEW_TICKETS_ONLY)(withBusiness(TicketPage))
