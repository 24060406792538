import {Alert} from "@mui/material"
import {useState} from "react"
import {
  Permission,
  SetUserPermissionMutation,
  useAllPermissionsQuery,
  useSetUserPermissionMutation,
} from "src/types/apollo"
import styled from "styled-components"

import {FetchResult} from "@apollo/client"
import LoadingText from "src/components/LoadingText"
import {PermissionsGrid} from "./PermissionsGrid"

interface Props {
  userId: string
  permissions: Permission[]
}

export const VIEW_TICKETS_ONLY = [Permission.CmsViewTickets]
export const VIEW_EDIT_AND_CREATE_TICKETS = [
  Permission.CmsViewTickets,
  Permission.CmsEditTickets,
  Permission.CmsCanCreateTickets,
]
export const CAN_CHECKIN = [
  Permission.CmsViewTickets,
  Permission.CmsCanViewCheckinTokens,
]

export default function UserPermissionsWidget({userId, permissions}: Props) {
  const [userPermissions, setUserPermissions] =
    useState<Permission[]>(permissions)
  const [setPermission, {loading: submitting}] = useSetUserPermissionMutation({
    onCompleted: (data) => {
      setUserPermissions(data.setUserPermission.permissions)
    },
  })
  const {
    data: allPermissionsData,
    loading: allPermissionsLoading,
    error: allPermissionsError,
  } = useAllPermissionsQuery()

  if (allPermissionsLoading) {
    return <LoadingText />
  }

  if (allPermissionsError) {
    return <Alert severity="error">{allPermissionsError.message}</Alert>
  }

  return (
    <Container>
      <PermissionsGrid
        allPermissions={allPermissionsData?.allPermissions ?? []}
        onChangeSelectedPermissions={(changed) =>
          Object.entries(changed).reduce<
            // biome-ignore lint/suspicious/noConfusingVoidType: legacy
            Promise<void | FetchResult<SetUserPermissionMutation>>
          >(
            (promise, [name, value]) =>
              promise.then(() =>
                setPermission({
                  variables: {
                    userId,
                    permissionName: name as Permission,
                    value,
                  },
                }),
              ),
            Promise.resolve(),
          )
        }
        selectedPermissions={Object.fromEntries(
          userPermissions.map((p) => [p, true]),
        )}
        gridItemProps={{
          xs: 12,
          md: 12,
        }}
        variant="switch"
        loading={submitting}
      />
    </Container>
  )
}

const Container = styled.div`
  min-width: 480px;
`
