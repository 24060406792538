import type {SvgIconComponent} from "@mui/icons-material"
import TicketIcon from "@mui/icons-material/ConfirmationNumberOutlined"
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices"
import QrCodeIcon from "@mui/icons-material/QrCode2Outlined"
import SettingsIcon from "@mui/icons-material/TuneOutlined"
import {
  CAN_CHECKIN,
  VIEW_EDIT_AND_CREATE_TICKETS,
  VIEW_TICKETS_ONLY,
} from "../features/users/components/UserPermissionsWidget"
import {Permission, type SideNavBusinessConfigQuery} from "../types/apollo"

export interface SidebarItem {
  title: string
  Icon?: SvgIconComponent
  path: string
  enabled?: (args: {
    permissions: Array<string>
    businessConfig: SideNavBusinessConfigQuery["me"]["associatedBusiness"]
  }) => boolean
}

interface SidebarSection {
  title: string
  Icon?: SvgIconComponent
  localStorageExpandKey: string
  children: Array<SidebarItem>
}

type SidebarStructure = Array<SidebarItem | SidebarSection>

export function isSection(
  maybeSection: SidebarSection | SidebarItem,
): maybeSection is SidebarSection {
  return "children" in maybeSection
}

const sidebarStructure: SidebarStructure = [
  {
    title: "Tickets",
    Icon: TicketIcon,
    localStorageExpandKey: "tickets",
    children: [
      {
        title: "Search",
        path: "/tickets",
        enabled: ({permissions}) =>
          VIEW_TICKETS_ONLY.every((permission) =>
            permissions.includes(permission),
          ),
      },
      {
        title: "Codes",
        path: "/ticket-codes",
        enabled: ({permissions}) =>
          VIEW_EDIT_AND_CREATE_TICKETS.every((permission) =>
            permissions.includes(permission),
          ),
      },
      {
        title: "Gatehouse",
        path: "/ticket-codes/gatehouse",
        enabled: ({permissions}) =>
          permissions.includes(Permission.CmsCanAccessGatehouseProcess),
      },
      {
        title: "Statistics",
        path: "/ticket-stats",
        enabled: ({permissions}) =>
          VIEW_TICKETS_ONLY.every((permission) =>
            permissions.includes(permission),
          ),
      },
      {
        title: "Insights",
        path: "/ticket-insights",
        enabled: ({permissions}) =>
          VIEW_TICKETS_ONLY.every((permission) =>
            permissions.includes(permission),
          ),
      },
      {
        title: "Create",
        path: "/tickets/new",
        enabled: ({permissions}) =>
          permissions.includes(Permission.CmsCanCreateTickets),
      },
      {
        title: "Timeslots",
        path: "/timeslots",
        enabled: ({permissions}) =>
          VIEW_TICKETS_ONLY.every((permission) =>
            permissions.includes(permission),
          ),
      },
    ],
  },
  {
    title: "Check-ins",
    Icon: QrCodeIcon,
    localStorageExpandKey: "checkIns",
    children: [
      {
        title: "Check in visitors",
        path: "/check-in",
        enabled: ({permissions}) =>
          CAN_CHECKIN.every((permission) => permissions.includes(permission)),
      },
      {
        title: "Statistics",
        path: "/checkin-stats",
        enabled: ({permissions}) =>
          VIEW_TICKETS_ONLY.every((permission) =>
            permissions.includes(permission),
          ),
      },
      {
        title: "Insights",
        path: "/checkin-insights",
        enabled: ({permissions}) =>
          VIEW_TICKETS_ONLY.every((permission) =>
            permissions.includes(permission),
          ),
      },
    ],
  },
  {
    title: "Integrations",
    Icon: ElectricalServicesIcon,
    localStorageExpandKey: "integrations",
    children: [
      {
        title: "Customer Lookup",
        path: "/integrations/external-user-lookup",
        enabled: ({permissions, businessConfig}) =>
          permissions.includes(Permission.CmsViewTickets) &&
          businessConfig?.externalUserSystemBaseUrl != null,
      },
    ],
  },
  {
    title: "Setup",
    Icon: SettingsIcon,
    localStorageExpandKey: "setup",
    children: [
      {
        title: "Configuration",
        path: "/setup-config",
        enabled: ({permissions}) =>
          permissions.includes(Permission.CmsAdministerTicketing),
      },
      {
        title: "Products",
        path: "/setup-products",
        enabled: ({permissions}) =>
          permissions.includes(Permission.CmsAdministerTicketing),
      },
      {
        title: "Users",
        path: "/setup-users",
        enabled: ({permissions}) =>
          permissions.includes(Permission.CmsCanEditUsers),
      },
      {
        title: "API Keys",
        path: "/api-keys",
        enabled: ({permissions}) =>
          permissions.includes(Permission.CmsAdministerApiKeys),
      },
    ],
  },
]

export default sidebarStructure
